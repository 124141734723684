(function ($) {

  // Use this function for the Common JS methods
  Common = {

    init: function () {

      // Load the functions
      this.headerOnScroll();         // Fixed header position on scroll
      this.headerToolbox.hide();     // Hide Header Toolbox
      this.topSubscribe();           // Top Subscribe link
      this.subscribeForm();          // Subscribe Form
      this.searchForm();             // Search Form
      this.topSearch();              // Top Search icon
      this.topFontSize();            // Top Font Size Icons
      this.mobileMenu();             // Mobile menu effects
      this.imageSlider();            // Image Slider animation
      this.leftSideMenu();           // Left menu effects
      this.locationBoxes();          //Location boxes on click
      this.footerIcons();            // Footer Social icons
      this.forms();                  // General forms
      this.gravityForms();           // Gravity Forms
      this.animatedElements();       // CSS animated elements by class
      this.donateForm();             // Donate Form
      this.otherForms();             // Other Forms
      this.gallery();                // Image Gallery
      this.translate();
      this.resetVideoStyling();
    },

    /*
     * Common Functions
     *
     */

    translate: function () {
      // $('#google_translate_element').click(function (e) {
      //   if (e.target.nodeName === 'P') {
      //     $('.skiptranslate.goog-te-gadget').toggle();
      //   }
      // });

      var dropdown = $('.lang-dropdown');
      var button_lang = $('.btn-lang');
      var button_selected_lang = $('.btn-selected-lang');
      var language = $('.language');

      button_lang.on('click', function (e) {
        $(this).toggleClass('opened');
        dropdown.toggleClass('opened');

        if ($(this).hasClass('opened')) {
          dropdown.slideDown();
        } else {
          dropdown.slideUp();
        }

        e.preventDefault();
      });

      button_selected_lang.on('click', function () {
        window.setTimeout(function () {
          location.reload();
        }, 100);
      });

      window.setTimeout(function () {
        var lang = $(".goog-te-menu-value span:first").text();

        button_selected_lang.each(function () {
          if (lang === $(this).data('lang')) {
            language.text($(this).data('code'));
          }
        });
      }, 1000);
    },

    // Header On Scroll
    headerOnScroll: function () {

      // Set the initial vars
      var last_scroll_top = 0,
        header = $('header'),
        wrap = $('.wrap'),
        header_nav = $('.header-nav');

      // Set header on scroll
      $(window).scroll(function () {

        // Action scroll online on desktop
        if (window.matchMedia("only screen and (min-width: 768px)").matches) {

          // Identify the scroll direction
          var self = $(this),
            state = self.scrollTop();

          if (state > last_scroll_top) {

            // Down Scroll action

            // Action when header nav out of viewport
            // if (!Common.Helper.isViewportVisible(header_nav[0])) {
            if ($(window).scrollTop() > 0) {

              header.addClass('fixed');
              wrap.addClass('fixed');
            }


          } else {

            // Up Scroll action

            // Action when reach the top of the document
            if ($(window).scrollTop() <= 0) {
              header.removeClass('fixed');
              wrap.removeClass('fixed');
            }

          }

          // Update last scroll position
          last_scroll_top = state;

        }

      });

      // Remove header fixed on mobile
      $(window).resize(function () {

        // Action scroll online on mobile
        if (window.matchMedia("only screen and (max-width: 768px)").matches) {

          header.removeClass('fixed');
          wrap.removeClass('fixed');

        }
      });


    },
    // Header Toolbox
    headerToolbox: {

      // Toggle the Header Toolbox
      toggle: function () {

        var header_toolbox = $('.header-toolbox'),
          toolbox_content = $('.toolbox-content');

        toolbox_content.fadeOut('slow');
        header_toolbox.fadeToggle('slow');

      },
      searchToggle: function () {

        var search_content = $('.header-search');
        search_content.fadeToggle('slow');

      },
      subscribeToggle: function () {

        var subscribe_content = $('.header-subscribe');
        subscribe_content.fadeToggle('slow');

      },
      hide: function () {

        // Hide Header Toolbox on click out
        $('body').click(function () {

          var header_toolbox = $('.header-toolbox'),
            toolbox_content = $('.toolbox-content');

          // Hide when not clicking over the toolbox
          if ($('.search-form:hover').length === 0 &&
            $('.subscribe-form:hover').length === 0) {
            header_toolbox.fadeOut('slow');
            toolbox_content.fadeOut('slow');
          }

        });
      }


    },
    // Top Subscribe
    topSubscribe: function () {
      var subscribe_link = $('.subscribe.menu-item a');

      subscribe_link.on('click', function () {

        //Toggle the header toolbox
        Common.headerToolbox.toggle();
        Common.headerToolbox.subscribeToggle();


        return false;
      });

    },
    // Search Form
    searchForm: function () {
      var search_form = $('.search-form');

      search_form.on('submit', function (e) {
        var search_field = $(this).find('.search-field');

        if (!search_field.val()) {
          e.preventDefault();
        }
      });

    },
    // Subscribe Form
    subscribeForm: function () {
      var subscribe_form = $('.subscribe-form');

      subscribe_form.on('submit', function (e) {

        var self = $(this),
          email = $('input[name="email"]').val(),
          action = $('input[name="action"]').val(),
          subscribe_alert = $('.subscribe-alert'),
          subscribe_spinner = $('.subscribe-spinner'),
          header_toolbox = $('.header-toolbox');
        // Proceed if valid inputs
        if (email.length) {
          // Hide elements
          self.hide();
          subscribe_spinner.show();

          // Create post data
          var url = self.attr('action'),
              data_array = self.serializeArray(),
              data_object = {},
              messages = {
                error_fields: 'There are still some empty fields that are required.',
                error_start_date: 'The Start Date field must be a valid date.',
                error_captcha: 'The reCAPTCHA field is not valid.'
              };

          $(data_array).each(function (i, field) {
            data_object[field.name] = field.value;

          });

          var recaptcha = data_object['g-recaptcha-response'];

          if (recaptcha === "" || recaptcha === 0) {
            throw_message('alert-danger', messages.error_captcha);
            return false;
          }

          // Initialise the response action
          var response_action = function () {
            subscribe_alert.text('Your subscription has been confirmed. You\'ve been added to our list and will hear from us soon.');
            subscribe_spinner.hide();
            subscribe_alert.show();
          };

          // Send the request
          $.ajax(
              {
                url: url,
                type: 'POST',
                data: data_object,
                dataType: 'json',
                success: function (data) {
                  // Successful response
                  if(data.http_status_code === 201 || data.http_status_code === 200) {
                    subscribe_alert.addClass('alert-success');
                    response_action();

                    // Close the window
                    setTimeout(function () {
                      header_toolbox.fadeOut('slow', function () {
                        self.show();
                        subscribe_alert.hide();
                        subscribe_alert.removeClass('alert-danger');
                        subscribe_alert.removeClass('alert-success');
                        subscribe_spinner.hide();
                      });
                    }, 2000);

                  }else {

                    subscribe_alert.addClass('alert-danger');
                    response_action();

                    // Display input again
                    setTimeout(function () {
                      subscribe_alert.fadeOut('slow', function () {
                        self.fadeIn('slow');
                      });
                    }, 2000);

                  }

                },
                error: function () {
                  // Any error handler
                  subscribe_alert.addClass('alert-danger');
                  subscribe_alert.text('An unexpected error has occurred. Please try again later.');

                  // Display input again
                  setTimeout(function () {
                    subscribe_alert.fadeOut('slow', function () {
                      self.fadeIn('slow');
                    });
                  }, 2000);

                },
                complete: function () {
                  console.log('complete');
                }

              });
        }

        // Stop default action
        e.preventDefault();
      });
    },
    // Top Search
    topSearch: function () {
      var search_icon = $('.search-icon');

      search_icon.on('click', function () {

        // Toggle the header toolbox
        Common.headerToolbox.toggle();
        Common.headerToolbox.searchToggle();

        return false;
      });

    },
    // Top Font Size Icons
    topFontSize: function () {
      var font_size_link = $('.font-size');

      font_size_link.click(function () {

        var self = $(this),
          font_available = $('.font-available');

        // Action if font exist
        if (font_available.length) {

          var font = parseInt(font_available.attr('data-font')),
            style = 'normal';

          // Action when link active
          if (!self.hasClass('inactive')) {

            font_size_link.removeClass('inactive');


            // Identify if zoom in / zoom out
            if (self.hasClass('minus')) {

              // Clear previous classes
              font_available.removeClass('normal');
              font_available.removeClass('medium');
              font_available.removeClass('large');
              font_available.removeClass('extra-large');

              // Calculate next size
              font_available.attr('data-font', (font - 1));
              font = parseInt(font_available.attr('data-font'));

              if (font >= 1) {

                self.removeClass('inactive');

                switch (font) {
                  case 1:
                    style = 'medium';
                    break;
                  case 2:
                    style = 'large';
                    break;
                  default:
                    style = 'normal';
                    break;
                }

              } else {
                style = 'normal';
                // No more options
                self.addClass('inactive');
                font_available.attr('data-font', 0);
              }
            }

            if (self.hasClass('plus')) {

              // Clear previous classes
              font_available.removeClass('normal');
              font_available.removeClass('medium');
              font_available.removeClass('large');
              font_available.removeClass('extra-large');

              // Calculate next size
              font_available.attr('data-font', (font + 1));
              font = parseInt(font_available.attr('data-font'));

              if (font <= 2) {

                self.removeClass('inactive');

                switch (font) {
                  case 1:
                    style = 'medium';
                    break;
                  case 2:
                    style = 'large';
                    break;
                  default:
                    style = 'extra-large';
                    break;
                }

              } else {
                // No more options
                style = 'extra-large';
                self.addClass('inactive');
                font_available.attr('data-font', 3);
              }
            }

            // Add new font class to content
            font_available.addClass(style);

          }


        }


        return false;
      });

    },
    // Mobile Menu
    mobileMenu: function () {

      var move = $('.move'),
        burguer = $('.burguer');

      // Toggle Menu on Click
      burguer.on('click', function () {

        var self = $(this);

        move.toggleClass('active');
        self.toggleClass('is-active');
        return false;

      });

    },
    // Image Slider Animation
    imageSlider: function () {

      var image_slider = $('.image-banner-slider');

      if (image_slider.length) {
        var banner_img = image_slider.find('.banner img'),
          banner_content = image_slider.find('.banner-content-item'),
          controls = $('.slide-nav li'),
          slide_control = $('.slide-control'),
          slider = null;


        // Define the slider animation
        var slider_animation = function (slider_number) {

          // Slider on selected slide
          if (typeof slider_number !== 'undefined') {

            banner_img.removeAttr('style');
            banner_img.removeClass('inversePulse');
            banner_img.css('z-index', 1);
            banner_img.eq(slider_number).css('z-index', 0);
            banner_content.removeClass('active');
            controls.removeClass('active');
            banner_content.removeAttr('style');
            banner_content.eq(slider_number).parents('.banner-content').addClass('fadeInUp');
            banner_content.eq(slider_number).addClass('active');
            controls.eq(slider_number).addClass('active');
            banner_img.eq(slider_number).addClass('inversePulse');

            banner_img.stop().animate({opacity: 0}, 500, function () {
              banner_img.eq(slider_number).css('z-index', 1);
            });


          } else {

            // Slider on autoplay
            banner_img.each(function () {
              var self = $(this);

              // Check for the current banner image animated
              if (self.hasClass('inversePulse')) {

                // Add class to next element
                self.css('z-index', 0);

                if (!self.next().length) {
                  banner_img.first().addClass('inversePulse');

                } else {
                  self.next().addClass('inversePulse');
                }

                // Animate banner content
                banner_content.each(function (index) {

                  var self_content = $(this);

                  if (self_content.hasClass('active')) {

                    // Animate current banner content container
                    self_content.parents('.banner-content').removeClass('fadeInUp');
                    self_content.parents('.banner-content').addClass('fadeOut');

                    self_content.stop().animate({opacity: 0}, 500, function () {

                      // Remove current active class
                      self_content.removeClass('active');
                      controls.removeClass('active');

                      // Animate next banner content
                      if (!self_content.next().length) {
                        banner_content.first().addClass('active');
                        controls.first().addClass('active');

                      } else {
                        self_content.next().addClass('active');
                        controls.eq(index).next().addClass('active');
                      }

                      self_content.removeAttr('style');
                      self_content.parents('.banner-content').removeClass('fadeOut');
                      self_content.parents('.banner-content').addClass('fadeInUp');


                    });

                    return;

                  }

                });

                // Remove class to current element
                setTimeout(function () {

                  self.stop().animate({opacity: 0}, 500, function () {
                    self.removeClass('inversePulse');
                    self.css('z-index', 1);

                  });

                }, 1000);

                return false;
              }

            });

          }
        };

        // Set the slider interval
        slider = setInterval(function () {
          slider_animation();
        }, 5000);


        // Unbind Slide Control Click
        slide_control.off('click');

        // Slide Control on Click
        slide_control.on('click', function () {

          var self = $(this),
            slide_index = self.data('slide'),
            slide = 0;

          if (parseInt(slide_index) > 0) {

            // Set the new slide index
            slide = slide_index - 1;

            // Trigger the new slide
            clearInterval(slider);
            slider_animation(slide);
            Common.imageSlider();

          }

          return false;

        });

      }

    },
    // Left Side Menu
    leftSideMenu: function () {

      var left_menu = $('.nav-left .nav');

      // Initialise a current class on the first element
      left_menu.find('ul').not('.sub-menu').find('li:first-child').addClass('current');

      if (left_menu.length) {

        // Get the selected menu cookie
        var selected_menu = Cookies.get('selected-menu'),
          body_classes = $('body').attr('class').split(' '),
          body_class = body_classes[body_classes.length - 1],
          menu_body_class = 'menu-' + body_class;

        // Create the selected menu cookie if not exists
        if (typeof selected_menu === 'undefined') {

          // Set the selected menu cookie
          Cookies.set('selected-menu', menu_body_class);

          // Get the cookie once again
          selected_menu = Cookies.get('selected-menu');

        } else {

          if (!left_menu.find('.' + selected_menu).length) {

            // Set the selected menu cookie
            Cookies.set('selected-menu', menu_body_class);

            // Get the cookie once again
            selected_menu = Cookies.get('selected-menu');
          }

        }

        // Create extra active and current classes
        left_menu.find('li').each(function () {

          var self = $(this),
            menu_classes = self.attr('class').split(' ');

          // Set selected menu if available
          if (typeof selected_menu !== 'undefined') {

            // Get the menu with the selected menu class
            if ($.inArray(selected_menu, menu_classes) > -1) {

              // Add selected menu class
              self.addClass('selected-menu');
            }
          }

          // Add menu classes
          if (self.hasClass('active')) {

            // Put a active-parent class to an active item with an active children
            if (self.hasClass('menu-item-has-children')) {

              var children = self.find('ul');

              children.find('li').each(function () {

                var self_children = $(this);

                if (self_children.hasClass('active')) {

                  self.addClass('active-parent');
                }

              });

            }

            // Put a current class to an active item and current page
            if (typeof body_classes !== 'undefined') {
              var menu_class = menu_classes[menu_classes.length - 1];

              if (menu_class === menu_body_class) {

                // Add current menu on the selected menu
                if (self.hasClass('selected-menu') || self.parents('li').hasClass('selected-menu')) {

                  self.addClass('current');

                  // Set the current parent
                  if (self.hasClass('menu-item-has-children')) {

                    self.addClass('current-parent');

                  } else {
                    self.parents('li').addClass('current-parent');
                  }

                }

              }

            }

          }

        });

        // Deselect classes on hover
        left_menu.find('a').hover(function () {
          left_menu.find('li').addClass('deselected');
        }, function () {
          left_menu.find('li').removeClass('deselected');
        });

        // Position the cursor on the current item.
        var initialise_position = function () {

          var current = left_menu.find('li.current a'),
            first_element = left_menu.find('li:first-child a'),
            left_menu_position = left_menu.offset().top,
            initial_position = left_menu_position - first_element;

          if (current.length) {
            var this_position = current.closest('li').offset().top,
              position_adjustment = (current.outerHeight() / 2) - 10,
              new_position = this_position - left_menu_position;

            initial_position = new_position + position_adjustment;
          }

          // Position the menu cursors on the menu item
          $('.menu-cursor').stop().animate({
            top: initial_position
          });

        };

        // Trigger the initial position on load
        initialise_position();

        // Trigger the initial position on resize
        $(window).resize(function () {
          initialise_position();
        });

        // Animate menu cursor
        left_menu.find('a').hover(
          function () {
            var self = $(this),
              left_menu_position = left_menu.offset().top,
              this_position = self.offset().top,
              position_adjustment = (self.outerHeight() / 2) - 10,
              new_position = this_position - left_menu_position + position_adjustment;

            self.parents('.nav-left').siblings('.menu-cursor').stop().animate({
              top: new_position
            });


          },
          function () {

            var self = $(this),
              current = left_menu.find('li.current a');

            if (current.length) {
              var left_menu_position = left_menu.offset().top,
                this_position = current.closest('li').offset().top,
                position_adjustment = (current.outerHeight() / 2) - 10,
                new_position = this_position - left_menu_position,
                initial_position = new_position + position_adjustment;

              self.parents('.nav-left').siblings('.menu-cursor').stop().animate({
                top: initial_position
              });
            }

          }
        );

      }

      // Left Menu Item on Click
      $(document).click(function (e) {

        var target = $(e.target);

        if (target.parents('.menu-item').length) {

          var self = target.parent('.menu-item'),
            current_parent = self;

          if (!self.hasClass('menu-item-has-children')) {

            if (self.parents('.menu-item-has-children').length) {
              current_parent = self.parents('.menu-item-has-children');
            }
          }

          // Get the menu parent class
          var menu_classes = current_parent.attr('class');

          if (typeof menu_classes !== 'undefined') {
            // if(menu_classes.length){

            // Remove any extra class
            var extra_classes = {
              'menu-item-has-children': '',
              'menu-item': '',
              'active-parent': '',
              'active': '',
              'current-parent': '',
              'current': '',
              'deselected': '',
              'selected-menu': ''
            };

            menu_classes = $.trim(menu_classes.replace(/menu-item-has-children|menu-item|active-parent|active|current-parent|current|deselected|selected-menu/gi, function (matched) {
              return extra_classes[matched];
            }));

            // Set the selected menu cookie
            Cookies.set('selected-menu', menu_classes);
          } else {
            // Remove any value for te selected menu cookie
            Cookies.remove('selected-menu');
          }

        } else {
          // Remove any value for te selected menu cookie
          // Cookies.remove('selected-menu');
        }

      });

    },

    // Animated Elements
    animatedElements: function () {

      // Get the animated elements
      var animate = function () {

        $(".animated").map(function () {

          var self = $(this),
            animation = self.data('animation'),
            delay = (self.data('delay')) ? self.data('delay') : 200;

          if (typeof animation !== 'undefined' && animation !== '') {

            // Animate when visible in viewport
            if (Common.Helper.isViewportVisible(self[0])) {

              // Wait 500ms to show
              setTimeout(function () {
                self.addClass(animation);
              }, delay);
            }

          }

        }).get();

      };

      // Animated elements on scroll
      $(window).on('scroll', function () {
        animate();
      });

      // Animated elements on resize
      $(window).on('resize', function () {
        animate();
      });

      // Animated elements on load
      animate();

    },
    // Footer Social Icons
    footerIcons: function () {

      // Animate on icons click
      $(document).on('mouseenter', '.footer-social  .icon', function () {

        var self = $(this);

        self.addClass('animated');
        self.addClass('tada');

      });

      $(document).on('mouseleave', '.footer-social  .icon', function () {

        var self = $(this);

        self.removeClass('animated');
        self.removeClass('tada');

      });

    },
    // Location Boxes
    locationBoxes: function () {

      // Location background images
      var location_image_link = $('.location-image a');

      if (location_image_link.length) {
        location_image_link.each(function () {

          var self = $(this),
            background_image = self.data('background');

          self.css('background-image', 'url(' + background_image + ')');

        });

        // Actions on hover
        location_image_link.on(
          {
            mouseenter: function () {
              //stuff to do on mouse enter
              var self = $(this),
                current_background = self.css('background-image'),
                background_image = self.data('background-hover');

              // Clone the background in the container
              self.parents('.location-image').css('background-image', current_background);

              self.stop(true, true).fadeTo(200, 0, function () {
                self.css('background-image', 'url(' + background_image + ')');
              }).fadeTo(400, 1);


              //self.css('background-image', 'url(' + background_image + ')');
            },
            mouseleave: function () {
              //stuff to do on mouse leave
              var self = $(this),
                current_background = self.css('background-image'),
                background_image = self.data('background');

              // Clone the background in the container
              self.parents('.location-image').css('background-image', current_background);

              self.stop(true, true).fadeTo(200, 0, function () {
                self.css('background-image', 'url(' + background_image + ')');
              }).fadeTo(400, 1);

              //self.css('background-image', 'url(' + background_image + ')');
            }
          }
        );

      }

      // Location boxes on Click
      var location = $('.location');

      if (location.length) {
        location.on('click', function () {

          var self = $(this),
            location_link = self.find('.location-link');

          if (location_link.length) {
            // Redirect to url
            window.location.replace(location_link.attr('href'));

          }

        });
      }

    },
    // Menu Active
    menuActive: function (menu, slug) {
      var left_menu_item = menu.find('li');

      if (left_menu_item.length) {
        left_menu_item.each(function () {

          var self = $(this);

          if (self.hasClass(slug)) {

            self.addClass('current');
            self.addClass('active');
          }

        });

      }

    },
    // General Forms
    forms: function () {

      // Select2 selects
      var select = $('.form-box select.form-control'),
        placeholder = select.data('placeholder');

      if (select.length) {

        if (typeof placeholder !== 'undefined') {

          select.select2({
            minimumResultsForSearch: Infinity,
            placeholder: placeholder
          });

        } else {
          select.select2({
            minimumResultsForSearch: Infinity
          });
        }
      }

      // Checkboxes and Radio Buttons
      var checkbox = $('.form-box input:checkbox, .form-box input:radio');

      if (checkbox.length) {

        checkbox.iCheck({
          checkboxClass: 'icheckbox_square-green',
          radioClass: 'iradio_square-green'
        });

      }
    },
    // Gravity Forms
    gravityForms: function () {

      // Select2 selects
      var select = $('.gfield .ginput_container select');
      var i;

      if (select.length) {
        for (i = 0; i < select.length; i++) {
          var el = $(select[i]);

          el.select2({
            minimumResultsForSearch: Infinity,
            placeholder: {
              id: '', // the value of the option
              text: el.find("option.gf_placeholder").text()
            }
          });
        }
      }

      // Checkboxes and Radio Buttons
      var checkbox = $('.gfield .ginput_container input:checkbox, .gfield .ginput_container input:radio');

      if (checkbox.length) {

        checkbox.iCheck({
          checkboxClass: 'icheckbox_square-green',
          radioClass: 'iradio_square-green'
        });

      }
    },
    // Donate Form
    donateForm: function () {

      var donate_form = $('.donate-form');

      // Error messages
      var throw_message = function (message_class, message_text) {
        var alert = donate_form.siblings('.alert'),
          message = alert.find('.alert-message'),
          classes = [
            'alert-warning',
            'alert-danger',
            'alert-success'
          ];

        // Hide the alert
        alert.hide();

        // Remove any inserted classes
        $.each(classes, function (i, val) {
          alert.removeClass(val);
        });

        // Remove any inserted text
        message.text('');

        // Add new message and class
        alert.addClass(message_class);
        message.text(message_text);

        // Display the alert
        alert.fadeIn();

        // Animate to top
        setTimeout(function () {
          $('html, body').animate({
            scrollTop: 0
          }, 500);
        }, 100);

      };

      // Loading action
      var loader = {

        alert: donate_form.siblings('.alert'),
        spinner: $('.donate-spinner'),
        // Display Loader
        show: function () {
          this.alert.hide();
          this.spinner.fadeIn();

          // Animate to top
          setTimeout(function () {
            $('html, body').animate({
              scrollTop: 0
            }, 500);
          }, 100);
        },
        // Hide Loader
        hide: function () {
          this.spinner.hide();
        }

      };

      // Donate Form on Submit
      donate_form.on('submit', function (e) {

        var self = $(this),
          url = self.attr('action'),
          data_array = self.serializeArray(),
          data_object = {},
          success = self.siblings('.donate-successful'),
          messages = {
            error_fields: 'There are still some empty fields that are required.',
            error_start_date: 'The Start Date field must be a valid date.',
            error_captcha: 'The reCAPTCHA field is not valid.'
          };

        // Store serialized data in the data object
        $(data_array).each(function (i, field) {
          data_object[field.name] = field.value;


        });

        // Search for empty required fields
        var required_field = $('.required'),
          recaptcha = data_object['g-recaptcha-response'],
          show_error = false;

        required_field.each(function () {

          var self = $(this),
            value = self.val();

          // Check if empty field
          if (value === "" || value === 0) {

            // Check if empty field is periodic and selected
            if (self.hasClass('type')) {

              if (data_object.type === 'periodic') {
                show_error = true;
              }

            } else {
              show_error = true;
            }

            // Show error if any
            if (show_error) {
              throw_message('alert-danger', messages.error_fields);
              return false;
            }

          }
        });

        // Proceed unless validation errors
        if (show_error) {
          return false;
        }

        // Check re-captcha
        if (recaptcha === "" || recaptcha === 0) {
          throw_message('alert-danger', messages.error_captcha);
          return false;
        }

        // Loading animation
        loader.show();

        // Send the form details to process
        $.ajax(
          {
            url: url,
            type: 'POST',
            data: data_object,
            dataType: 'json',
            success: function (data) {

              // Successful response
              if (data.valid) {

                // Throw message
                setTimeout(function () {
                  throw_message('alert-success', data.message);

                  // Hide form and show happy message
                  self.fadeOut(function () {
                    success.fadeIn('slow');
                  });

                }, 200);

              } else {

                // Throw message
                setTimeout(function () {
                  throw_message('alert-danger', data.message);
                }, 200);
              }

            },
            error: function (data) {

              // Animation back to initial state
              loader.hide();

              // Throw message
              setTimeout(function () {
                throw_message('alert-danger', data.responseText);
              }, 200);
            },
            complete: function (data) {

              // Animation back to initial state
              loader.hide();
            }

          });

        // Stop default action
        e.preventDefault();

      });

      // Form animations
      if (donate_form.length) {

        // Reason select box
        var reason = $('select[name="reason"]'),
          other_reason = $('.other_reason');

        reason.on('change', function () {
          var self = $(this),
            value = self.val();

          reason.find('option').each(function () {

            var option = $(this),
              option_value = option.val();

            if (option_value === value) {
              if (option.hasClass('other')) {

                // Enable extra field
                other_reason.slideDown('slow');
              } else {

                // Disable extra field
                other_reason.slideUp('slow');
              }
            }

          });

        });

        // Type radio option
        var type = $('input[name="type"]'),
          periodic_details = $('.periodic-details');
        type.on('ifChecked', function () {

          var self = $(this),
            value = self.val();

          if (value === 'periodic') {
            // Enable extra field
            periodic_details.slideDown('slow');
          } else {
            periodic_details.slideUp('slow');
          }

        });

        // Another donation button
        var reset_donation = donate_form.siblings().find('.reset');

        reset_donation.on('click', function () {

          var success = donate_form.siblings('.donate-successful'),
            alert = donate_form.siblings('.alert');

          // Clear donate form inputs
          donate_form.find('input[type="text"],input[type="number"], input[type="email"], select, textarea').val('');

          // Show donate form again
          success.fadeOut();
          alert.fadeOut();

          setTimeout(function () {
            donate_form.fadeIn();
          }, 500);

          return false;

        });

      }

    },
    // Other Forms
    otherForms: function () {

      var form = $('.form');

      // Error messages
      var throw_message = function (message_class, message_text) {
        var alert = form.siblings('.alert'),
          message = alert.find('.alert-message'),
          form_box = $('.form-box'),
          classes = [
            'alert-warning',
            'alert-danger',
            'alert-success'
          ];

        // Hide the alert
        alert.hide();

        // Remove any inserted classes
        $.each(classes, function (i, val) {
          alert.removeClass(val);
        });

        // Remove any inserted text
        message.text('');

        // Add new message and class
        alert.addClass(message_class);
        message.text(message_text);

        // Display the alert
        alert.fadeIn();

        // Animate to top
        setTimeout(function () {
          $('html, body').animate({
            scrollTop: form_box.position().top
          }, 500);
        }, 100);

      };

      // Loading action
      var loader = {

        alert: form.siblings('.alert'),
        spinner: $('.spinner'),

        // Display Loader
        show: function () {
          this.alert.hide();
          this.spinner.fadeIn();

          // Animate to top
          setTimeout(function () {
            var form_box = $('.form-box');

            $('html, body').animate({
              scrollTop: form_box.position().top
            }, 500);
          }, 100);
        },
        // Hide Loader
        hide: function () {
          this.spinner.hide();
        }


      };

      // Form on Submit
      form.on('submit', function (e) {

        var self = $(this),
          url = self.attr('action'),
          data_array = self.serializeArray(),
          data_object = {},
          success = self.siblings('.successful'),
          messages = {
            error_fields: 'There are still some empty fields that are required.',
            error_start_date: 'The Start Date field must be a valid date.',
            error_captcha: 'The reCAPTCHA field is not valid.'
          };

        // Store serialized data in the data object
        $(data_array).each(function (i, field) {
          data_object[field.name] = field.value;


        });

        // Search for empty required fields
        var required_field = $('.required'),
          recaptcha = data_object['g-recaptcha-response'],
          show_error = false;

        required_field.each(function () {

          var self = $(this),
            value = self.val();

          // Check if empty field
          if (value === "" || value === 0) {

            // Check if empty field is periodic and selected
            show_error = true;

            // Show error if any
            if (show_error) {
              throw_message('alert-danger', messages.error_fields);
              return false;
            }

          }
        });

        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        // const forms = document.querySelectorAll('.needs-validation');

        // // Loop over them and prevent submission
        // forms.forEach((form) => {
        //   form.addEventListener('submit', (event) => {
        //     if (!form.checkValidity()) {
        //       event.preventDefault();
        //       event.stopPropagation();
        //     }
        //     form.classList.add('was-validated');
        //   }, false);
        // });

        // Proceed unless validation errors
        if (show_error) {
          return false;
        }

        // Check re-captcha
        if (recaptcha === "" || recaptcha === 0) {
          throw_message('alert-danger', messages.error_captcha);
          return false;
        }

        // Loading animation
        loader.show();

        // Send the form details to process
        $.ajax(
          {
            url: url,
            type: 'POST',
            data: data_object,
            dataType: 'json',
            success: function (data) {

              // Successful response
              if (data.valid) {

                // Throw message
                setTimeout(function () {
                  throw_message('alert-success', data.message);

                  // Hide form and show happy message
                  self.fadeOut(function () {
                    success.fadeIn('slow');
                  });

                }, 200);

              } else {

                // Throw message
                setTimeout(function () {
                  throw_message('alert-danger', data.message);
                }, 200);
              }

            },
            error: function (data) {

              // Animation back to initial state
              loader.hide();

              // Throw message
              setTimeout(function () {
                throw_message('alert-danger', data.responseText);
              }, 200);
            },
            complete: function (data) {

              // Animation back to initial state
              loader.hide();
            }

          });

        // Stop default action
        e.preventDefault();

      });

      // Form animations
      if (form.length) {

        // Another enquiry button
        var reset_form = form.siblings().find('.reset');

        reset_form.on('click', function () {

          var success = form.siblings('.successful'),
            alert = form.siblings('.alert');

          // Clear form inputs
          form.find('input[type="text"],input[type="number"], input[type="email"], select, textarea').val('');

          // Show form again
          success.fadeOut();
          alert.fadeOut();

          setTimeout(function () {
            form.fadeIn();
          }, 500);

          return false;

        });

      }

    },
    // Image Gallery
    gallery: function () {

      var gallery = $('.gallery');

      if (gallery.length) {

        var gallery_slider = function () {

          var swiper_options = {};

          // Get the Swiper options
          if (window.matchMedia("only screen and (min-width: 768px)").matches) {

            swiper_options = {
              loop: false,
              slidesPerView: 3,
            };

          } else {

            swiper_options = {
              loop: false,
              slidesPerView: 2,
            };

          }

          // Call the Swiper
          var swiper = new Swiper('.swiper-container', swiper_options);

        };

        // Initialise the Gallery Slider
        gallery_slider();

        // Update the Swiper Slider on Resize
        $(window).resize(function () {
          gallery_slider();
        });


        // On View All click
        var all = $('.btn-all');

        all.on('click', function () {

          var fresco = $('.fresco');
          fresco.first().trigger('click');

          return false;

        });

      }

    },

    /*
     * Inside the 'what-is-a-future-school' page, Change the video wraper height.
     *
     */
    resetVideoStyling: function() {
      const video = document.getElementsByClassName('video-container-in-landing-page')[0];

      if (video) {
        const videoWraper = video.closest('.content-area.col-xs-12.col-nospace');

        if (videoWraper) {
          videoWraper.classList.add('video-wraper-in-future-school-page');
        }
      }
    },

    /*
     * Common Helper Functions
     *
     */
    Helper: {
      // Identify visible element in ViewPort // Any part of the element
      isViewportVisible: function (el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
          el = el.offsetParent;
          top += el.offsetTop;
          left += el.offsetLeft;
        }

        return (
          top < (window.pageYOffset + window.innerHeight) &&
          left < (window.pageXOffset + window.innerWidth) &&
          (top + height) > window.pageYOffset &&
          (left + width) > window.pageXOffset
        );
      },
      // Set the same height for sibling elements by class
      setSameHeight: function (el) {

        var setElementHeight = function () {

          // Set the heights only on desktop, otherwise clear style
          if (window.matchMedia("only screen and (min-width: 768px)").matches) {

            var max_height = 0;

            el.css('height', '');
            el.each(function () {

              var self = $(this);
              max_height = self.height() > max_height ? self.height() : max_height;

            }).height(max_height);

          } else {
            el.css('height', '');
          }
        };

        // Initialise element heights on resize
        $(window).on('resize', function () {
          setElementHeight();
        });

        // Initialise the element  heights on load
        setElementHeight();

      }
    }
  };

  // Load Events
  // $(document).ready(Common.init());


})(jQuery); // Fully reference jQuery after this point.
