(function ($) {

    // Use this function for the Blocks JS methods
    Blocks = {

        init: function () {

            // Load the functions
            this.heightsContentBlocks(); // Content Blocks Heights
            this.clickContentBlocks(); // Content Blocks On Click
            this.slideToggleContentBox(); // Content Box Toggle

        },

        /*
         * Blocks Functions
         *
         */

        // Content Blocks Heights
        heightsContentBlocks: function () {

            // List content to set the heights
            Common.Helper.setSameHeight($('.box-wrap'));
            Common.Helper.setSameHeight($('.location'));
            Common.Helper.setSameHeight($('.content-feature'));

        },
        // Content Blocks On Click
        clickContentBlocks: function () {

            var col_box = $('.col-box');

            col_box.on('click', function () {

                var self = $(this),
                    button = self.find('.btn'),
                    arrow_link = self.find('.arrow-link');

                // Redirect to url if button is present
                if (button.length) {
                    var url = button.attr('href');

                    if (!arrow_link.is(':hover')) {
                        window.location.href = url;
                    }


                }

            });

        },
        // Content Box Toggle
        slideToggleContentBox: function () {

            $(document).on('click', '.more', function () {

                var self = $(this);

                self.toggleClass('active');
                self.parents('.box-header').siblings('.box-text').slideToggle('slow');

                return false;

            });

            // Set box initial on resize
            $(window).on('resize', function () {

                if (window.matchMedia("only screen and (min-width: 768px)").matches) {

                    $('.more').removeClass('active');
                    $('.more').parents('.box-header').siblings('.box-text').show();

                }

            });

        }


    };

    // Load Events
    // $(document).ready(Blocks.init());

})(jQuery); // Fully reference jQuery after this point.